jQuery(document).ready(function($){

    $.easing.def = 'easeInOutQuart';

    var loaded = false;

    var st;
    var D;
    var dh;
    var fh;

    var has_scrolled = false;

    var document_title = document.title;

    // $('.scrolldown').on('click',function(e){
    //     e.preventDefault();
    //     var target = $(this).attr('href');
    //     $('html, body').animate({
    //         scrollTop: $(target).offset().top - $('.header').innerHeight() - 43
    //     }, 1200);
    // });

    // $('.hamburger').on('click',function(e){
    //     // console.log('click');
    //     if($('body').hasClass('nav-is-open')){
    //         $('.header--nav').fadeOut(450).removeClass('is-open');
    //         $('body').removeClass('nav-is-open');
    //     }else{
    //         $('.header--nav').fadeIn(450).addClass('is-open');
    //         $('body').addClass('nav-is-open');
    //     }
    // });

    $(window).on('load',function(){
        if(loaded === false){
            $(window).trigger('resize');
            loaded = true;
        }
    });

    $(window).on('resize',function(){
        var $block_black = $('.home .blocks .columns').eq(0).find('.block-black');
        var h = $('.home .blocks .columns').eq(0).find('.block-grey').height() + $('.home .blocks .columns').eq(1).find('.block-black').height() + ($(window).width() * 0.0625);
        $block_black.css('height',h+'px');
    });

    if($('.slider').length){
        $('.slider').each(function(){

            var $slider = $(this);
            $slider.slick({
                dots: false,
                infinite: false,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                cssEase: 'ease',
                draggable: true,
                centerMode: true,
                variableWidth: false,
                centerPadding: '6.25vw',
                arrows: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            infinite: false,
                            dots: false,
                            arrows: false,
                            draggable: true,
                            centerMode: true,
                            centerPadding: '6.25vw',
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            infinite: false,
                            dots: false,
                            arrows: false,
                            centerMode: false,
                            centerPadding: '6.25vw',
                        }
                    }
                ]
            });

            $slider.find('.js-slider-prev').click(function() {
                $slider.slick('slickGoTo', parseInt($slider.slick('slickCurrentSlide'))-1);
            });

            $slider.find('.js-slider-next').click(function() {
                $slider.slick('slickGoTo', parseInt($slider.slick('slickCurrentSlide'))+1);
            });

        });
    }

    $(window).load(function(){

        if($('.progressbar').length){

            st = $(document).scrollTop();
            D = document;
            dh = Math.max(Math.max(D.body.scrollHeight, D.documentElement.scrollHeight), Math.max(D.body.offsetHeight, D.documentElement.offsetHeight), Math.max(D.body.clientHeight, D.documentElement.clientHeight));
            fh = dh - $(window).height();

            var progress = 0;

            $(document).on('scroll',function(){
                st = $(document).scrollTop();
                progress = st / (fh / 100);
                $('.progressbar').css('width',progress+'%');
            });

        }

    });
    
    $('.hovervideo--text a').each(function(){
    	var $video_link = $(this);
    	var target = $('#hovervideo--video');
    	
    	$video_link.on('click',function(e){
    		e.preventDefault();
		}).on('mouseenter',function(){
	        var $trigger = $(this);
	        
	        if ($(target).get(0).paused) {
	            $(target).get(0).play();
	            $('body').addClass('hovervideo-is-playing');
	        }
	    }).on('mouseleave',function(){
	        var $trigger = $(this);
	        
	        if ($(target).get(0).play) {
	            $('body').removeClass('hovervideo-is-playing');
                $(target).get(0).pause();
	        }
	    });
    });
    
    /*
     * Hovervideo - Wout
     * -----------------------------------------------------------------
     */
//    $('.video--trigger').on('click',function(e){e.preventDefault();});
//    $('.video--trigger').on('mouseover',function(){
//        var $trigger = $(this);
//        var target = $trigger.attr('href');
//        if ($(target).get(0).paused) {
//            $(target).get(0).play();
//            $('body').addClass('hovervideo-is-playing');
//        }
//    });
//    $('.video--trigger').on('mouseout',function(){
//        var $trigger = $(this);
//        var target = $trigger.attr('href');
//        if ($(target).get(0).play) {
//            $('body').removeClass('hovervideo-is-playing');
//            setTimeout(function(){
//                $(target).get(0).pause();
//            },600);
//        }
//    });
    /*
     * -----------------------------------------------------------------
     */

    $(document).on('scroll',function(){ has_scrolled = true; });

    $('.js-wp-home').each(function(){
        new Waypoint.Inview({
            element: this,
            enter: function(direction){
                if(direction === 'up' && Foundation.MediaQuery.current !== 'small'){
                    var client_name = $('#'+this.element.id).find('span.title').text();
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text(' ' + client_name  +'.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            },
            entered: function(direction) {
                if(direction === 'down' && Foundation.MediaQuery.current !== 'small'){
                    var client_name = $('#'+this.element.id).find('span.title').text();
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text(' ' + client_name  +'.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });
    });

    if( $('.js-wp-home').length ){
    	var $first_wp = $('.js-wp-home').get(0);
        var wp_length = $('.js-wp-home').length;
        var $last_wp = $('.js-wp-home').get(wp_length-1);

        var first_inview = new Waypoint.Inview({
            element: $first_wp,
            exit: function(direction){
                if(direction === 'up' && Foundation.MediaQuery.current !== 'small'){
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text('.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });

        var last_inview = new Waypoint.Inview({
            element: $last_wp,
            exited: function(direction){
                if(direction === 'down' && Foundation.MediaQuery.current !== 'small'){
                    $('.js-client-name').fadeOut(200);
                    setTimeout(function(){
                        $('.js-client-name').text('.');
                        $('.js-client-name').fadeIn('fast');
                    },200);
                }
            }
        });
    }
    
    if( $('.flowplayer-video').length ){
    	
    	$('.flowplayer-video').each(function(index, element){
    		$(this).find(".fp-message").html('');
    		
    		var player = flowplayer(index);
    		
    		player.bind("ready", function(event, api) {
    		
    			player.stop();
	    		
	    		var flowplayer_waypoint = new Waypoint.Inview({
	                element: element,
	                enter: function(direction){
	                    player.play();
	                },
	                entered: function(direction){
	                    player.play();
	                },
	                exited: function(direction){
                		player.stop();
	                },
	            });
        	});
    	});
    	
    }
    
    if( $( 'section.case' ).length ){
    	
    	var $navigation = $('header.header').first();
    	var $offset_navigation = $navigation.first().outerHeight();
    	var $offset_header = $('section.case--header').first().outerHeight();
    	
    	$offset = ( 0 - $offset_header ) + $offset_navigation;
    	
    	$('section.case--header').first().waypoint( function( direction ) {
    			if( direction === 'down') {
    				$navigation.addClass('inverse');
    			}
    			else {
    				$navigation.removeClass('inverse');
    			}
			},{ 
				offset: $offset 
			}
		);
    	
    }

    if($('.arrow-js').length){
        $('.arrow-js').each(function(){
            var $self = $(this);
            if($(this).find('a').length){
                var $link = $(this).find('a');
                $link.append('<div class="arrow arrow-large"><div></div></div>');
            }
        });
    }

    $(window).on('blur',function(){ 
        document.title = document_title;
    });

    $(window).on('focus',function(){ 
        document.title = document_title;
    });

    // form 

    $(window).on('load',function(){
        $('form input, form textarea').on('keypress',function(){
            var $self = $(this);
            if(!$self.hasClass('wpcf7-not-valid')){
                if(user_typing === false){
                    $self.closest('li').addClass('is-focus');
                    user_typing = true;
                }
            }
        });
        $('form input').each(function(){
            var $self = $(this);
            if($self.val()){
                $self.closest('li').addClass('is-focus');
            }
        });
        $('form textarea').each(function(){
            var $self = $(this);
            if($self.val()){
                $self.closest('li').addClass('is-focus');
            }
        });
    });

    var user_typing = false;

    $('form input, form textarea').on('keypress',function(){
        var $self = $(this);
        if(user_typing === false){
            $self.closest('li').addClass('is-focus');
            user_typing = true;
        }
    });

    $('form input, form textarea').on('blur change',function(){
        var $self = $(this);
        if(!$self.val()){
            $(this).closest('li').removeClass('is-focus');
        }
        user_typing = false;
    });

}); 